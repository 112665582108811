:root {
  font-size: 90%;
  --color-background: #303030;
  --color-font: #ffffff;


  /* cores dos accordions em active principle */
  --doses-soft: #8DA894;
  --doses-hard: #57755d;
  --doses-dark: #000;
}

body {
  margin: 0;
  padding: 0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root {
  background: var(--color-background);
  color: var(--color-font);
}

.container-context {
  background: var(--color-background);
}

#outlined-basic-helper-text {
  font-size: 1.1rem;
}
